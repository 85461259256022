<template>
  <q-footer elevated class="bg-black text-white">
    <q-toolbar class="small-footer">
      <q-toolbar-title >
<!--        <a class="text-white q-mr-lg" href="tel:+33714155324" target="_blank"><q-icon name="local_phone" /></a>-->
        <a class="text-white q-mr-lg" href="mailto:pbtc76@gmail.com" target="_blank"><q-icon name="mail" /></a>
        <a class="text-white q-mr-lg" href="https://www.instagram.com/pbtc76" target="_blank"><q-icon :name="mdiInstagram" /></a>
        <a class="text-white q-mr-lg" href="https://www.facebook.com/pavillybarentintennisclub/" target="_blank"><q-icon :name="mdiFacebook" /></a>
        <a class="text-white q-mr-lg" href="https://twitter.com/pbtc76" target="_blank"><q-icon :name="mdiTwitter" /></a>
        <span class="q-mr-lg mobile-hide"><q-img class="mobile-hide" src="@/assets/copyright-symbol.png" width="25px" height="25px" alt="Copyright logo" /> 2022 Pavilly Barentin Tennis Club.</span>
      </q-toolbar-title>
    </q-toolbar>
  </q-footer>
</template>

<script>
import { mdiInstagram } from '@quasar/extras/mdi-v6'
import { mdiFacebook } from '@quasar/extras/mdi-v6'
import { mdiTwitter } from '@quasar/extras/mdi-v6'
export default {
  name: "PbtcFooter",
  created () {
    this.mdiInstagram = mdiInstagram
    this.mdiFacebook = mdiFacebook
    this.mdiTwitter = mdiTwitter
  }
}
</script>

<style scoped lang="sass">
  .small-footer
    min-height: 30px
</style>