<template>
  <div class="q-pa-md partenaire-carousel-width">
    <q-carousel
        height="300px"
        animated
        v-model="slide"
        infinite
        :autoplay="autoplay"
        transition-prev="slide-right"
        transition-next="slide-left"
        @mouseenter="autoplay = false"
        @mouseleave="autoplay = true"
    >
      <q-carousel-slide :name="1" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap mobile-hide">
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/matmut.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/mcdonalds.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/pavilly1.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/perfenco.png" /></div>
        </div>
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap desktop-hide">
          <div class="col-12"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/matmut.png" /></div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="2" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap mobile-hide">
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/mcdonalds.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/pavilly1.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/perfenco.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/toyota.png" /></div>
        </div>
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap desktop-hide">
          <div class="col-12"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/mcdonalds.png" /></div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="3" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap mobile-hide">
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/pavilly1.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/perfenco.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/toyota.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/wilson.png" /></div>
        </div>
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap desktop-hide">
          <div class="col-12"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/pavilly1.png" /></div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="4" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap mobile-hide">
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/perfenco.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/toyota.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/wilson.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/Logo-NWS.png" /></div>
        </div>
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap desktop-hide">
          <div class="col-12"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/perfenco.png" /></div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="5" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap mobile-hide">
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/toyota.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/wilson.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/Logo-NWS.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/matmut.png" /></div>
        </div>
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap desktop-hide">
          <div class="col-12"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/toyota.png" /></div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="6" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap mobile-hide">
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/wilson.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/Logo-NWS.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/matmut.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/mcdonalds.png" /></div>
        </div>
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap desktop-hide">
          <div class="col-12"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/wilson.png" /></div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="7" class="column no-wrap">
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap mobile-hide">
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/Logo-NWS.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/matmut.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/mcdonalds.png" /></div>
          <div class="col-3"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/pavilly1.png" /></div>
        </div>
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap desktop-hide">
          <div class="col-12"><q-img class="rounded-borders fit" src="@/assets/partenaire-logo/Logo-NWS.png" /></div>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: "PbtcPartenaireCarousel",
  setup () {
    return {
      slide: ref(1),
      autoplay: ref(true)
    }
  }
}
</script>

<style scoped>
.partenaire-carousel-width {
  width: 50%;
}
</style>