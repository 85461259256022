<template>
  <div class="row fit flex flex-center">
    <div class="window-width row">
      <q-img height="35rem" src="@/assets/accueil.jpg">
        <div class="fit flex flex-center">
            <h1 class="text-h1 w-100 q-mt-sm q-mb-sm">Pavilly Barentin <br /> <span class="text-secondary">Tennis Club</span></h1>
            <q-btn to="/pricing" class="q-mb-lg" icon="sports_tennis" size="lg" color="primary" text-color="white" label="Devenir Adhérent" />
        </div>
      </q-img>
    </div>
    <div class="window-width q-pa-md row q-mb-lg">
      <div class="col-4 flex flex-center mobile-hide">
        <h2 class="text-blue-10 text-bold text-h2">DATES À
          RETENIR</h2>
      </div>
      <div class="col-lg-4 col-6 flex flex-center">
        <h3 class="w-100 text-h4 text-bold text-blue-10">Inscriptions</h3>
        <br />
        <p class="text-body1 w-100">
          Mardi 22 août de 11h à 19h
        </p>
        <p class="text-body1 w-100">
          <span class="text-bold"> Forum des associations </span>: les samedis 26 août et 2 septembre
        </p>
      </div>
      <div class="col-lg-4 col-6">
        <h3 class="text-bold text-h4 text-blue-10">Cours d'essais</h3>
        <q-list>
          <q-expansion-item expand-separator icon="person_filled" label="Adultes">
            <q-list bordered>
            <q-item>
              <q-item-section>Mardi 5 Septembre de 18h à 19h30</q-item-section>
            </q-item>
          </q-list>
          </q-expansion-item>
          <q-expansion-item expand-separator icon="escalator_warning" label="Jeunes">
            <q-list>
              <q-item>
                <q-item-section>Mercredi 6 Septembre de 10h à 11h (3 à 6 ans)</q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Mercredi 6 Septembre de 14h à 15h (7 à 12 ans)</q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Mercredi 6 Septembre de 15h30 à 16h30 (13 à 18 ans)</q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>
        </q-list>
      </div>
    </div>
    <div class="window-width q-pa-md row q-mb-lg">
      <div class="col-6 flex flex-center  mobile-hide">
        <q-img src="@/assets/book-court.jpg" width="50%" />
      </div>
      <div class="col-lg-6 col-12">
        <h3 class="text-bold">Réserver votre terrain !</h3>
        <p class="text-body1">
          Vous êtes déjà adhérents du PBTC ? Cliquez sur le premier bouton pour réserver un court sur TenUp !
        </p>
        <p class="text-body1">
          Vous êtes licenciés dans un autre club mais vous souhaitez venir fouler nos superbes courts ? Nous vous comprenons, donc cliquez sur le deuxième bouton, nos courts sont ouverts aux locations horaires.
        </p>
        <q-btn href="https://tenup.fft.fr/adherent/reservations" target="_blank" class="q-mb-md" icon="sports_tennis" size="lg" color="blue-10" text-color="white" label="Réservation adhérents" /><br />
        <q-btn href="https://tenup.fft.fr/location-horaire" target="_blank"  icon="sports_tennis" size="lg" color="blue-10" text-color="white" label="Réservation non adhérents" />
      </div>
    </div>
    <div class="bg-grey-10 text-white window-width q-pa-md row">
      <div class="col-6 flex flex-center mobile-hide">
        <span class="text-h3"> Les adhérents parlent du club ! </span>
      </div>
      <div class="col-lg-6 col-12">
        <PbtcAvisCarousel />
      </div>
    </div>
    <div class="window-width q-pa-xl row">
      <div class="col">
        <q-icon color="blue-10" size="xl" name="star" />
        <br />
        <span class="text-h2 text-bold">5/5</span>
        <br />
        <span class="text-subtitle1">Notes google</span>
      </div>
      <div class="col">
        <q-icon color="blue-10" size="xl" name="groups" />
        <br />
        <span class="text-h2 text-bold">320</span>
        <br />
        <span class="text-subtitle1">Adhérents</span>
      </div>
      <div class="col">
        <q-icon color="blue-10" size="xl" name="sports_tennis" />
        <br />
        <span class="text-h2 text-bold">5</span>
        <br />
        <span class="text-subtitle1">Courts à réserver</span>
      </div>
    </div>
<!--    <div class="window-width row text-white justify-between">-->
<!--      <q-parallax src="@/assets/background.png">-->
<!--        <div class="row full-width">-->
<!--          <div class="col-12 col-lg-6">-->
<!--            <span class="text-h4"> Prochain forum des Assos </span>-->
<!--            <PbtcCountdown class="q-mt-lg" />-->
<!--          </div>-->
<!--          <div class="col-12 col-lg-6">-->
<!--          <span class="text-h4">-->
<!--            A Pavilly / Barentin-->
<!--          </span>-->
<!--            <div class="row">-->
<!--              <div class="col-12 col-lg-6">-->
<!--                <div class="q-mt-lg"><q-icon class="q-mr-lg text-h6" name="schedule" /><span class="text-h6">Le 28/08/2022 de 10h à 18h</span></div>-->
<!--              </div>-->
<!--              <div class="col-12 col-lg-6">-->
<!--                <div class="q-mt-lg"><q-icon class="q-mr-lg text-h6" name="pin_drop" /><span class="text-h6">Complexe sportif de la Viardière,<br /> 76570 Pavilly</span></div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-12 col-lg-6">-->
<!--                <div class="q-mt-lg"><q-icon class="q-mr-lg text-h6" name="schedule" /><span class="text-h6">Le 03/09/2022 de 10h à 18h</span></div>-->
<!--              </div>-->
<!--              <div class="col-12 col-lg-6">-->
<!--                <div class="q-mt-lg"><q-icon class="q-mr-lg text-h6" name="pin_drop" /><span class="text-h6">Salle Pierre de Courbertin,<br /> 76360 Barentin</span></div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="q-mt-lg">-->
<!--              <q-btn to="/pricing" class="mobile-hide" size="lg" color="white" text-color="black" label="En savoir plus" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </q-parallax>-->

<!--    </div>-->
    <PbtcPartenaireCarousel />
    <div class="bg-primary window-width text-white text-h4 q-pa-lg row">
      <div class="col-6 flex flex-center">
        <span>N’oubliez pas de réserver votre terrain chez nous !</span>
      </div>
      <div class="col-6 flex flex-center">
        <q-btn to="/booking" size="xl" color="black" text-color="white" label="Réserver" />
      </div>
    </div>
  </div>
</template>

<script>
  import PbtcPartenaireCarousel from "@/components/PbtcPartenaireCarousel";
  import PbtcAvisCarousel from "@/components/PbtcAvisCarousel";
  export default {
    name: "PbtcHome",
    components: {PbtcAvisCarousel, PbtcPartenaireCarousel}
  }
</script>

<style scoped>
  .w-100 {
    width: 100%;
  }
</style>