<template>
  <div class="q-pa-md partenaire-carousel-width">
    <q-carousel
        height="300px"
        animated
        v-model="slide"
        infinite
        :autoplay="autoplay"
        class="bg-grey-10"
        transition-prev="slide-right"
        transition-next="slide-left"
        @mouseenter="autoplay = false"
        @mouseleave="autoplay = true"
    >
      <q-carousel-slide :name="1" class="bg-grey-10 column no-wrap flex-center q-pa-lg">
        <div class="bg-grey-10 q-mt-md text-center text-h5">
          “ Un club convivial avec un enseignement de qualité, dans une ambiance sympathique et dynamique. Quelle chance d’avoir ce club tout près de chez soi ! ”
        </div>
        <br/>
        <div class="bg-grey-10 q-mt-md full-width row">
          <div class="bg-grey-10 col-10"></div>
          <div class="bg-grey-10 col-2">
            <span class="text-subtitle1"> Pascaline </span>
            <br>
            <span class="text-subtitle2"> Adhérente </span>
          </div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="2" class="bg-grey-10 column no-wrap flex-center q-pa-lg">
        <div class="q-mt-md text-center text-h5">
          “ Super club, très convivial et un enseignement au top ! ”
        </div>
        <br/>
        <div class="q-mt-md full-width row">
          <div class="col-10"></div>
          <div class="col-2">
            <span class="text-subtitle1"> Benoît </span>
            <br>
            <span class="text-subtitle2"> Adhérent </span>
          </div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide :name="3" class="bg-grey-10 column no-wrap flex-center q-pa-lg">
        <div class="q-mt-md text-center text-h5">
          “ Il y a pleins d’animations, l’ambiance est sympa et les profs sont supers ”
        </div>
        <br/>
        <div class="q-mt-md full-width row">
          <div class="col-10"></div>
          <div class="col-2">
            <span class="text-subtitle1"> Tom - 11 ans </span>
            <br>
            <span class="text-subtitle2"> Jeune adhérent </span>
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: "PbtcAvisCarousel",
  setup () {
    return {
      slide: ref(1),
      autoplay: ref(true)
    }
  }
}
</script>

<style scoped>
</style>