<template>

  <q-layout view="hHh Lpr fff">

    <PbtcHeader/>

    <q-page-container>
      <q-page class="flex flex-center">
          <router-view :key="$route.fullPath"/>
      </q-page>
    </q-page-container>

    <PbtcFooter />

  </q-layout>
</template>

<script>
import PbtcFooter from "./components/PbtcFooter";
import PbtcHeader from "./components/PbtcHeader";

export default {
  name: 'App',
  components: {
    PbtcHeader,
    PbtcFooter
  },
  mounted() {
    document.title = "PBTC";
  }
}
</script>

<style lang="sass">
#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50

.fill-available
  width: -webkit-fill-available
.border-thin-accent
  border-color: var(--q-accent)
  border-style: solid
  border-width: 1px
.border-thin-primary
  border-width: 1px
  border-style: solid
  border-color: var(--q-primary)
.border-thin-positive
  border-width: 1px
  border-style: solid
  border-color: var(--q-positive)
.border-thin-negative
  border-width: 1px
  border-style: solid
  border-color: var(--q-negative)
.border-primary
  border-style: solid
  border-color: var(--q-primary)
.router-link-exact-active
  color: var(--q-accent)

</style>
