<template>
  <q-tabs align="right">
    <q-route-tab to="/home" label="Accueil" />
<!--    <q-route-tab to="/story" label="Qui sommes nous ?" />-->
    <q-route-tab to="/pricing" label="Tarifs" />
    <q-route-tab to="/booking" label="Réserver un terrain" />
  </q-tabs>
</template>

<script>

import {ref} from 'vue'

export default {
  name: "PbtcNavigation",
  emits: ['setDrawerOpen'],
  props: {
    drawerOpen: Boolean,
    admin: Boolean,
    supervisor: Boolean
  },
  setup() {
    return {
      menu: ref(null)
    }
  }
}
</script>

<style scoped>

</style>