<template>
  <div class="row fit flex flex-center">
    <div class="window-width q-pa-lg row flex-center">
      <div class="col-lg-8 col-12 row">
        <div class="col flex flex-center">
          <h3 class="text-bold">Pourquoi réserver un <span class="text-blue-10">court</span> chez nous ?</h3>
          <p class="text-body1">Le Pavilly Barentin Tennis Club vous propose à travers l’outil TENUP de la fédération de pouvoir jouer avec un invité, ce dernier étant une personne non adhérente au club.</p>
        </div>
        <div class="col flex flex-center">
          <q-img width="50%" src="@/assets/book-court2.jpg"></q-img>
        </div>
      </div>
    </div>

    <h3 class="w-100 text-bold">Les étapes</h3>
    <div class="q-pa-md">
      <q-stepper class="q-mb-md" v-model="step" ref="stepper" color="primary" animated>
        <q-step
            :name="1"
            title="Réserver"
            icon="book_online"
            :done="step > 1"
        >
          Réservez une date pour accéder à votre court.
        </q-step>

        <q-step
            :name="2"
            title="Valider"
            icon="done"
            :done="step > 2"
        >
          Validez votre court en utilisant un ticket.
        </q-step>

        <q-step
            :name="3"
            title="Jouer"
            icon="sports_tennis"
            :done="step > 3"
        >
          Maintenant profitez de nos meilleurs terrains à Pavilly/Barentin
        </q-step>

        <template v-slot:navigation>
          <q-stepper-navigation>
            <q-btn @click="$refs.stepper.next()" color="primary" :label="step === 4 ? 'Jouer' : 'Continuer'" />
            <q-btn v-if="step > 1" flat color="primary" @click="$refs.stepper.previous()" label="Retour" class="q-ml-sm" />
          </q-stepper-navigation>
        </template>
      </q-stepper>

      <q-markup-table separator="cell" flat bordered>
        <thead>
        <tr>
          <th class="text-left">Prix des tickets pour un court</th>
          <th class="text-right text-primary">OFFRE N°1</th>
          <th class="text-right text-primary">OFFRE N°2</th>
          <th class="text-right text-primary">OFFRE N°3</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-left">Nombre de ticket(s)</td>
          <td class="text-right">1</td>
          <td class="text-right">5</td>
          <td class="text-right">10</td>
        </tr>
        <tr>
          <td class="text-left">Prix</td>
          <td class="text-right">6€</td>
          <td class="text-right">25€</td>
          <td class="text-right">40€</td>
        </tr>
        <tr>
          <td class="text-left">Réservation</td>
          <td class="items-center"><q-btn href="https://tenup.fft.fr/location-horaire" color="primary" label="Réserver" /></td>
        </tr>
        </tbody>
      </q-markup-table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: "PbtcBooking",
  setup () {
    return {
      step: ref(1)
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>