<template>
  <q-header elevated class="bg-primary text-white">
    <q-toolbar>
      <q-toolbar-title align="left">
        <q-img width="100px" src="@/assets/logo.png" alt="Logo Pavilly Barentin Tennis Club" />
      </q-toolbar-title>
      <PbtcNavigation />
    </q-toolbar>
  </q-header>
</template>

<script>
import PbtcNavigation from "@/components/PbtcNavigation";

export default {
  name: "PbtcHeader",
  components: {
    PbtcNavigation
  }
}
</script>

<style lang="sass" scoped>
</style>