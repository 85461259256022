import { createRouter, createWebHistory } from 'vue-router'
import PbtcHome from "@/views/PbtcHome";
import PbtcPricing from "@/views/PbtcPricing";
import PbtcBooking from "@/views/PbtcBooking";

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Accueil',
    component: PbtcHome
  },
  {
    path: '/pricing',
    name: 'Tarifs',
    component: PbtcPricing
  },
  {
    path: '/booking',
    name: 'Réserver un court',
    component: PbtcBooking
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
