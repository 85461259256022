<template>
  <div class="row fit flex flex-center">
    <div class="window-width q-pa-lg row">
      <div class="col-6 flex flex-center">
        <h4 class="text-bold">Pourquoi devenir adhérent chez nous ?</h4>
        <p class="text-body1">Nous sommes un club de tennis qui souhaite offrir à tout le monde des cours de qualité, des animations ainsi que des compétitions accessibles pour tous.</p>
      </div>
      <div class="col-6 flex flex-center">
        <h4 class="w-100 text-bold text-blue-10">Avantages</h4>
        <q-list>
          <q-item>
            <q-item-section avatar>
              <q-avatar color="white" text-color="blue-10" icon="done" />
            </q-item-section>

            <q-item-section>50€ A PARTIR DE LA 3EME COTISATION DANS LA MEME FAMILLE</q-item-section>
          </q-item>
          <q-item>
            <q-item-section avatar>
              <q-avatar color="white" text-color="blue-10" icon="done" />
            </q-item-section>

            <q-item-section>ÉTUDIANT (cours + adhésion) -20%</q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
    <h3 class="w-100 text-bold">ADHÉSION OBLIGATOIRE POUR PROFITER DES TARIFS</h3>
    <div class="col-12 col-lg-6 q-pa-lg row">
      <div class="col-lg col-12">
        <q-card bordered>
          <q-card-section>
            <div class="text-h6 text-bold w-100 text-primary">Cours Jeunes</div>

          </q-card-section>

          <q-separator inset />

          <q-card-section>
            <q-list>
              <q-item>
                <q-item-section>Baby tennis</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">3 x 30€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>5/6 ans</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">3 x 35€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>7/12 ans</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">3 x 40€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>13/18 ans</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">3 x 45€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Perfectionnement</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary" >3 x 80€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Competition</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">3 x 110€</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>

          <q-separator inset />
        </q-card>
      </div>
      <div class="col-lg col-12">
        <q-card bordered>
          <q-card-section>
            <div class="text-h6 text-bold text-primary">Cours Adultes</div>
          </q-card-section>

          <q-separator inset />

          <q-card-section>
            <q-list>
              <q-expansion-item expand-separator label="Hommes -45ans">
                <q-list bordered>
                  <q-item>
                    <q-item-section>Non classé - 30/1</q-item-section>
                    <q-item-section side>
                      <q-item-label class="text-primary">3 x 85€</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>30 - 15/1</q-item-section>
                    <q-item-section side>
                      <q-item-label class="text-primary">3 x 60€</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>Seconde série</q-item-section>
                    <q-item-section side>
                      <q-item-label class="text-primary">3 x 50€</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-expansion-item>
              <q-expansion-item expand-separator label="Femmes +18ans  Hommes +45ans">
                <q-list bordered>
                  <q-item>
                    <q-item-section>Non classé - 30/4</q-item-section>
                    <q-item-section side>
                      <q-item-label class="text-primary">3 x 85€</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>30/3 - 30</q-item-section>
                    <q-item-section side>
                      <q-item-label class="text-primary">3 x 60€</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>15/5 - 15/3</q-item-section>
                    <q-item-section side>
                      <q-item-label class="text-primary">3 x 50€</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>A partir de 15/2</q-item-section>
                    <q-item-section side>
                      <q-item-label class="text-primary">3 x 40€</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-expansion-item>
              <q-item>
                <q-item-section>Competition equipe</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">3 x 55€</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>

          <q-separator inset />
        </q-card>
      </div>
      <div class="col-lg col-12">
        <q-card bordered>
          <q-card-section>
            <div class="text-h6 text-primary text-bold">Adhésions</div>
          </q-card-section>

          <q-separator inset />

          <q-card-section>
            <q-list>
              <q-item>
                <q-item-section>Adhésion Adulte</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">159€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Jeu libre adulte (heures creuses)</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">110€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Adhésion jeune</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">89€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Parents/Enfants</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">80€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Location horaire</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">12€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Ticket invité</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary" >6€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Cours particulier</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">30€</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Cours collectif (4pers max)</q-item-section>
                <q-item-section side>
                  <q-item-label class="text-primary">40€</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>

          <q-separator inset />
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PbtcPricing"
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>