import { createApp } from 'vue';
import App from './App.vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import router from './router'

//Config errorHandler to send errors to backend
// initErrorHandler()

//Register all plugin
const vueApp = createApp(App)
    // .use(store)
    .use(router)
    .use(Quasar, quasarUserOptions);
vueApp.mount('#app');

// axios.get('/ws/whoami').then(response => finishSetup(vueApp, response));

// function finishSetup(vueApp, response) {
//     axios.defaults.headers.post['X-XSRF-TOKEN'] = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`))[1]
//     vueApp.config.globalProperties.user = response.data;
//     vueApp.mount('#app');
// }

// function initErrorHandler() {
//     window.onerror = function (msg, url, lineNo, columnNo, error) {
//         let clientSideError = {
//             msg: msg,
//             user: vueApp.config.globalProperties.user.uid,
//             url: url,
//             lineNumber: lineNo,
//             columnNumber: columnNo,
//             error: error
//         };
//         if (!clientSideError.msg.includes('ResizeObserver')) {
//             axios.post('/ws/error', clientSideError).then(r => console.log(r));
//         }
//     };
// }

